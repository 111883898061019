<template>
<div class="UpcomingMaintenances" v-if="upcomingMaintenances.length  > 0">
  <div class="title">{{ textUpcoming }}</div>
  <template>
    <div v-for="maintenance in upcomingMaintenances" :key="maintenance.id">
      <upcoming-maintenance-item :maintenance="maintenance" :status-page-language="statusPageLanguage"/>
    </div>
  </template>
</div>
</template>
<script>
import UpcomingMaintenanceItem from '@/components/StatusPage/Maintenance/UpcomingMaintenanceItem.vue'

export default {
  components: { UpcomingMaintenanceItem },
  props: {
    upcomingMaintenances: {
      required: true,
      type: Array
    },
    statusPageLanguage: {
      required: false
    }
  },

  computed: {
    textUpcoming () {
      if (this.statusPageLanguage.text_upcoming) {
        return this.statusPageLanguage.text_upcoming
      }

      return 'Upcoming'
    }
  }
}
</script>

<style lang="scss" scoped>
.UpcomingMaintenances {
  .title {
    font-size: 27px;
    margin-bottom: 30px;
    margin-top: 40px;
    font-weight: 600;
  }

  .no-maintenance {
    height: 120px;
    background: rgba(25, 34, 29, 0.03);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(25, 34, 29, .5);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: -10px 0;
  }
}
</style>
