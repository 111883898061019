<template>
  <div class="MonitorNodeName"
       :class="classes">
    <img :src="getNodeFlag(node)"
         class="flag"
         v-if="showFlag "/>
    <div class="content">
      <div class="title pl-2">{{ node.title }}</div>
<!--      <a :href="ipLink"-->
<!--         class="ip pl-2 pt-1"-->
<!--         target="_blank">-->
<!--        {{ node.ip }}-->
<!--      </a>-->
    </div>
  </div>
</template>

<script>
// import { getIpResolveUrl } from '@/services/utils.js'

export default {
  props: {
    node: {
      required: true,
      type: Object
    },

    showFlag: {
      required: false,
      type: Boolean,
      default: true
    },

    inline: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  methods: {
    getNodeFlag (node) {
      switch (node.location) {
        case 'new_york':
        case 'new-york': return require('@/assets/images/countries/united-states-of-america.svg')
        case 'ashburn': return require('@/assets/images/countries/united-states-of-america.svg')
        case 'frankfurt': return require('@/assets/images/countries/germany.svg')
        case 'nuremberg': return require('@/assets/images/countries/germany.svg')
        case 'helsinki': return require('@/assets/images/countries/finland.svg')
        case 'singapore': return require('@/assets/images/countries/singapore.svg')
        case 'bangalore': return require('@/assets/images/countries/india.svg')
        case 'toronto': return require('@/assets/images/countries/canada.svg')
        case 'london': return require('@/assets/images/countries/united-kingdom.svg')
        case 'sao-paulo': return require('@/assets/images/countries/brazil.svg')
        case 'los-angeles': return require('@/assets/images/countries/united-states-of-america.svg')
        case 'johannesburg': return require('@/assets/images/countries/south-africa.svg')
        case 'tokyo': return require('@/assets/images/countries/japan.svg')
        case 'bahrain': return require('@/assets/images/countries/bahrain.svg')
        case 'paris': return require('@/assets/images/countries/france.svg')
        case 'sydney': return require('@/assets/images/countries/australia.svg')
        case 'madrid': return require('@/assets/images/countries/spain.svg')
        case 'stockholm': return require('@/assets/images/countries/sweden.svg')
        default: return require('@/assets/images/countries/japan.svg')
      }
    }
  },

  computed: {
    // ipLink () {
    //   return getIpResolveUrl(this.node.ip)
    // },

    classes () {
      return {
        inline: this.inline
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorNodeName {
  display: inline-flex;
  align-items: center;

  .flag {
    width: 24px;
    height: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    //margin-left: 10px;
  }

  .title {
    font-size: 14px;
    margin-bottom: 2px;
    line-height: 1;
  }

  .ip {
    font-size: 12px;
    line-height: 1;
    color: #b2b6c3;

    &:hover {
      color: map-get($colors, purple-1);
    }
  }

  &.inline {
    align-items: center;

    .flag {
      width: 15px;
      height: 15px;
      ///border: 1px solid #0000;
      border-radius: 50px;
      //box-shadow: 0 1px 2px #00000057;
    }

    .content {
      flex-direction: row;
      align-items: center;
    }

    .title {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }

    .ip {
      margin-left: 6px;
      margin-top: 2px;
    }
  }
}
</style>
