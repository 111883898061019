<template>
  <div>
    <StatusPageUptime
      :statusPage="statusPage"
      :status-page-language="statusPageLanguage"
    />
  </div>
</template>

<script>
import StatusPageUptime from '@/components/StatusPage/StatusPageUptime'

export default {
  components: {
    StatusPageUptime
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      statusPageLanguage: {}
    }
  },

  created () {
    if (this.statusPage && this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .Status {
    width: 100vw;
    height: 100vh;
  }
</style>
