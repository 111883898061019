<template>
  <div ref="snapshot"
      class="rect"
       :style="{'cursor':showLocationTooltip ? 'pointer' : 'default'}"
      :class="classes"> </div>

</template>

<script>
import tippy from 'tippy.js'

import Vue from 'vue'
import Dropdown from './UptimeHistoryDropdown.vue'

export default {
  props: {
    item: {
      required: true,
      type: Object
    },

    x: {
      required: true
    },

    width: {
      required: true
    },
    statusPageLanguage: {
      required: true,
      type: Object
    },
    uptimeThreshold: {
      required: false,
      type: Number,
      default: 1.00
    },

    showLocationTooltip: {
      required: false,
      type: Number,
      default: 1
    },

    userTimeZone: {
      required: false,
      type: String
    },

    monitorCreatedAt: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      isFirstInit: true
    }
  },

  mounted () {
    if (this.$mq === 'desktop' && this.showLocationTooltip && this.item.snapshots[0] !== 1) {
      this.initDropdown()
    }
  },

  methods: {
    initDropdown () {
      if (this.isFirstInit) {
        this.isFirstInit = false
        let ComponentClass = Vue.extend(Dropdown)
        let instance = new ComponentClass({
          propsData: {
            item: this.item,
            statusPageLanguage: this.statusPageLanguage,
            userTimeZone: this.userTimeZone,
            monitorCreatedAt: this.monitorCreatedAt
          }
        })

        instance.$mount() // pass nothing

        tippy(this.$refs.snapshot, {
          content: instance.$el,
          appendTo: () => document.body,
          interactive: true,
          theme: 'light-border',
          animation: 'shift-away-subtle',
          placement: 'bottom',
          arrow: true,
          inertia: true,
          trigger: 'click',
          maxWidth: 'none'
        })
      }
    }
  },

  computed: {
    classes () {
      let classes = []

      // if (this.item.snapshots.length === 0) {
      //   classes.push('empty')
      // }

      if (this.item.uptime < this.uptimeThreshold) {
        classes.push('danger')
      }

      if (this.showLocationTooltip) {
        classes.push(this.$mq)
      }

      return classes
    }
  },
  watch: {
    item: {
      handler: function () {
        if (this.showLocationTooltip) {
          this.initDropdown()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>

  .rect {
    background-color: #1aaf5d;
    transition: 0.2s all;
    height: 30px;
    width: 5px;
    border-radius: 1.5px;
    //max-resolution: 5px;

    &.empty {
      background-color: #D1D3D2;
      height: 20px;
    }

    &.danger {
      background-color: #F2994A;
      height: 40px;
    }

    &.desktop:hover {
      //fill: #000;
      opacity: .8;
      transform: scaleY(1.4);
    }
  }
</style>
