<template>
  <div class="MonitorBadgeUptime">
    <div v-if="statusPage.uptime_percentage_style">
      {{ monitor.uptime | uptimeWholeNumber }}
    </div>
    <div v-else>
      {{ monitor.uptime | uptimeWithoutRounding }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    },
    statusPage: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorBadgeUptime {
    //@include inline-flex-center;

    //padding: 0 20px;
    height: 24px;

    font-size: 18px;
    opacity: .6;
    text-align: right;
    //border-radius: 2px;
    // color: #fff;
    // background-color: #AFA3FF;
  }
</style>
