<template>
  <div class="Monitor">
      <div class="header">
        <div class="title">
          <div v-if="sslWarning" class="status-info">
            <icon-info width="20" height="20" />
          </div>
          <badge-status v-else :monitor="monitor" />
          <div class="monitor-name truncate" :class="$mq">
            {{ monitor | monitorNameOrUrl }}
          </div>
        </div>
        <div class="right">
          <div class="toolbar">
            <badge-uptime :monitor="monitor" :statusPage="statusPage"/>
          </div>
        </div>
      </div>

      <uptime-history v-if="isNodesLoaded" :monitor="monitor" :uptime-threshold="statusPage.uptime_threshold" :status-page-language="statusPageLanguage" :show-location-tooltip="statusPage.show_location_tooltip" />
  </div>
</template>

<script>
import BadgeStatus from './MonitorBadgeStatus.vue'
import BadgeUptime from './MonitorBadgeUptime.vue'
import UptimeHistory from './UptimeHistory/UptimeHistory.vue'

// import UptimeCheck from './UptimeCheck/UptimeCheck.vue'
// import IncidentHistory from './IncidentsHistory/IncidentHistory.vue'

export default {
  components: {
    BadgeStatus,
    BadgeUptime,
    UptimeHistory
    // UptimeCheck,
    // IncidentHistory
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },

    statusPage: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    },
    isNodesLoaded: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sslWarning () {
      if (this.monitor.ssl_check === 1 && this.monitor.status === 'online') {
        return this.monitor.ssl_certificate_state !== 'valid' && !this.monitor.ssl_certificate
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .Monitor {
    display: flex;
    flex-direction: column;

    // margin-bottom: 24px;
    // padding: 20px;
    // border-radius: 4px;
    // background-color: #F7F9FC;

    .title {
      display: flex;
      align-items: center;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .monitor-name {
      margin-right: 10px;
      color: #1F1F23;
      font-size: 20px;
      line-height: 1;
      padding:2px 0;

    }
    .status-info {
      display: flex;
      margin-right: 10px;
    }
  }
</style>
