<template>
  <div class="MonitorBadgeStatus"
       :class="classes">
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  computed: {
    label () {
      if (this.monitor.status === 'online') {
        return '✓'
      }

      return '✗'
    },

    classes () {
      let classes = []
      if (this.monitor.status) {
        classes = [this.monitor.status]
      } else {
        classes = ['offline']
      }
      classes.push(this.$mq)

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorBadgeStatus {
    @include inline-flex-center;

    height: 20px;
    width: 20px;

    font-size: 12px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
    color: #fff;

    &.online {
      background-color: map-get($badge-colors, online);
    }

    &.offline {
      background-color: map-get($badge-colors, danger);
    }

    &.mobile {
      font-size: 10px;
      padding: 0 10px;
      height: 20px;
    }
  }
</style>
