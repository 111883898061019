import store from '@/store/index.js'

export default snapshot => {
  return {
    ...snapshot,
    node: getNode(snapshot)
  }
}

const getNode = snapshot => {
  return store.getters['nodes/getNodeById'](snapshot.node_id)
}
