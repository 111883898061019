<template>
  <div class="Incidents">
    <div class="title">{{ textRecentIncidents }}</div>
    <template v-if="isShowHistory">
      <div class="date" v-for="(historyItem, index) in history" :key="index">
        <div class="date-title" v-if="historyItem.incidents.length !== 0">
          {{ textHistoryItemDate(historyItem.date) }}
        </div>
        <incident-item v-for="incident in historyItem.incidents"
                       :key="incident.id"
                       :incident="incident"
                       :status-page-language="statusPageLanguage"
        />
      </div>
    </template>
    <div v-else class="no-incidents">
      {{ textNoIncidentsReported }}
    </div>
  </div>
</template>

<script>
import IncidentItem from './IncidentsItem.vue'
import getUserLocale from 'get-user-locale'
import dayjs from 'dayjs'

const dateRange = 30

export default {
  components: {
    IncidentItem
  },

  props: {
    incidents: {
      required: true,
      type: Array
    },
    statusPage: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      history: []
    }
  },

  created () {
    for (let i = 0; i < dateRange; i++) {
      this.history.push({
        date: dayjs().subtract(i, 'day').startOf('day').unix(),
        incidents: []
      })
    }

    this.incidents.forEach(incident => {
      const date = dayjs.unix(incident.created_at).startOf('day').unix()
      let historyItem = this.history.find(item => item.date === date)

      if (historyItem) {
        historyItem.incidents.push(incident)
      }
    })
  },

  methods: {
    textHistoryItemDate (timestamp) {
      const locale = this.statusPageLanguage.date_and_time_language?.type ?? getUserLocale()

      const day = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })

      return day.format(new Date(timestamp * 1000))
    }
  },

  computed: {
    isShowHistory () {
      return !!this.history.find(item => item.incidents.length !== 0)
    },

    textRecentIncidents () {
      if (this.statusPageLanguage.text_recent_incidents) {
        return this.statusPageLanguage.text_recent_incidents
      }

      return 'Recent Incidents'
    },

    textNoIncidentsReported () {
      if (this.statusPageLanguage.text_no_incidents_reported) {
        return this.statusPageLanguage.text_no_incidents_reported
      }

      return 'No incidents reported.'
    }
  },

  filters: {
    date (timestamp) {
      const locale = this.statusPageLanguage.date_and_time_language.type

      const day = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })

      return day.format(new Date(timestamp * 1000))
    }
  }
}
</script>

<style lang="scss" scoped>
  .Incidents {
    .title {
      font-size: 27px;
      margin-bottom: 30px;
      margin-top: 40px;
      font-weight: 600;
    }

    .date {
      margin-bottom: 40px;
    }

    .date-title {
      font-size: 20px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-weight: 500;
      //border-bottom: 1px solid map-get($colors, gray-3);
    }

    .no-incidents {
      height: 120px;
      background: rgba(25, 34, 29, 0.03);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(25, 34, 29, .5);
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin: -10px 0;
    }
  }
</style>
