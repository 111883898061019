<template>
  <div>
    <div class="maintenance-info" v-if="statusPage.live_maintenance">
      <div class="d-flex align-items-center justify-content-start mb-2">
        <img class="mr-3" src="@/assets/images/warning.svg" alt="Warning Icon" /><span class="in-progress-text">{{ textMaintenanceInProgress }}</span>
      </div>
      <h2 class="maintenance-title">{{ statusPage.live_maintenance.name }}</h2>
      <nl2br tag="p" class-name="maintenance-text" :text="statusPage.live_maintenance.description" />
      <span class="timing-text">{{ textMaintenanceStarted + ' ' + maintenanceStarted }}</span>
    </div>
    <div v-for="showMonitor in showMonitors" :key="showMonitor.id">
      <div v-if="showMonitor.monitors" style="margin-bottom: 30px;">
        <h2 v-if="showMonitor.title"
            class="group-title">{{ showMonitor.title }}</h2>
        <div>
          <template v-if="!isSnapshotsLoading && isNodesLoaded">
            <monitor-item
              v-for="monitor in showMonitor.monitors"
              :key="monitor.id"
              :monitor="monitor"
              :status-page="statusPage"
              :status-page-language="statusPageLanguage"
              :isNodesLoaded="isNodesLoaded"
            />
          </template>
        </div>
      </div>
      <div v-else>
        <div>
          <template v-if="!isSnapshotsLoading && isNodesLoaded">
            <monitor-item
              :monitor="showMonitor"
              :status-page="statusPage"
              :status-page-language="statusPageLanguage"
              :isNodesLoaded="isNodesLoaded"
            />
          </template>
        </div>
      </div>
    </div>
    <div v-if="!showMonitors.length && !isSnapshotsLoading && isNodesLoaded">
      No monitors added. Please add monitors from your account.
    </div>
    <div v-if="isSnapshotsLoading || !isNodesLoaded">
      <status-placeholder v-for="item in 3" :key="item"></status-placeholder>
    </div>

    <upcoming-maintenances
      :upcoming-maintenances="statusPage.upcoming_maintenances"
      :status-page-language="statusPageLanguage"
    />

    <status-maintenance-history :status-page="statusPage"/>

    <status-page-incidents
      :incidents="statusPage.incidents"
      :status-page="statusPage"
      :status-page-language="statusPageLanguage"
    />
  </div>
</template>
<script>
import MonitorItem from './Monitor/Monitor.vue'
import StatusPageIncidents from './Incidents/Incidents.vue'
import StatusPlaceholder from './StatusPlaceholder'

import statusPagesApi from '@/api/statusPagesApi.js'
import dayjs from 'dayjs'
import monitorsApi from '@/api/monitorsApi'
import StatusMaintenanceHistory from '@/components/StatusPage/Maintenance/status-maintenance-history.vue'
import UpcomingMaintenances from '@/components/StatusPage/Maintenance/UpcomingMaintenances.vue'
import Nl2br from 'vue-nl2br'

export default {
  components: {
    UpcomingMaintenances,
    StatusMaintenanceHistory,
    MonitorItem,
    StatusPageIncidents,
    StatusPlaceholder,
    Nl2br
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      monitors: [],
      groups: [],
      showMonitors: [],
      since: dayjs().subtract(90, 'day').startOf('day'),
      till: dayjs().endOf('day'),
      isSnapshotsLoading: true,
      isNodesLoaded: false
    }
  },

  async created () {
    await Promise.all([this.loadSnapshots(), this.loadGroups()])

    if (this.groups.length !== 0) {
      this.showMonitors = this.monitors.reduce((acc, elem) => {
        const relatedGroup = this.groups.find(group => group.monitors.includes(String(elem.id)))
        if (relatedGroup) {
          const availableGroupIndex = acc.findIndex(item => String(item.groupId) === String(relatedGroup.id))

          if (availableGroupIndex === -1) {
            acc.push({
              groupId: relatedGroup.id,
              title: relatedGroup.title,
              monitors: [elem]
            })
          } else {
            acc[availableGroupIndex].monitors.push(elem)
          }
        } else {
          acc.push(elem)
        }

        return acc
      }, [])
    } else {
      this.showMonitors = this.monitors.slice()
    }
  },

  methods: {
    async loadGroups () {
      try {
        let previewData = await JSON.parse(localStorage.getItem('status_page_monitors_preview'))

        if (previewData && this.$route.query.preview && previewData.slug === this.statusPage.slug) {
          this.groups = previewData.groups.filter(item => item.monitors.length)
        } else {
          const groups = await monitorsApi.getMonitorGroups(this.statusPage.id)

          this.groups = groups.map(item => ({
            id: String(item.id),
            title: item.name,
            monitors: item.status_page_group_monitors.map(monitor => String(monitor.monitor_id))
          }))
        }
      } catch {
        this.groups = []
      }
    },
    async loadSnapshots () {
      const data = await statusPagesApi.getSnapshots(this.statusPage.slug)

      let monitors = this.statusPage.monitors.slice()

      monitors = monitors.filter(monitor => {
        return data.find(i => i.monitor_id === monitor.id)
      }).map(monitor => ({
        ...monitor,
        snapshots: data.find(i => i.monitor_id === monitor.id).snapshots
      }))

      this.monitors = monitors
      this.isSnapshotsLoading = false
      await this.getNodes()
    },
    async getNodes () {
      const nodes = await statusPagesApi.getNodes()
      this.monitors = this.monitors.map(item => {
        item.snapshots = item.snapshots.map(snapshot => {
          return {
            ...snapshot,
            node: nodes.find(node => node.id === snapshot.node_id)
          }
        })
        return item
      })
      this.isNodesLoaded = true
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    textMaintenanceInProgress () {
      if (this.statusPageLanguage.text_maintenance_in_progress) {
        return this.statusPageLanguage.text_maintenance_in_progress
      }
      return 'Maintenance in progress'
    },

    textMaintenanceStarted () {
      if (this.statusPageLanguage.text_maintenance_started) {
        return this.statusPageLanguage.text_maintenance_started
      }
      return 'Started'
    },

    maintenanceStarted () {
      if (this.statusPage.live_maintenance) {
        return dayjs(this.statusPage.live_maintenance.start).from(dayjs())
      }
      return null
    }

  }
}
</script>

<style lang="scss" scoped>
  .container {
    max-width: 984px;
  }

  .maintenance-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: rgba(25, 34, 29, 0.03);
    border-radius: 12px;
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding: 40px;
    margin: 10px 0 40px 0;

    .maintenance-text {
      font-size: 18px;
    }

    .timing-text {
      margin-top: 20px;
      font-size: 16px;
      color: rgba(25, 34, 29, .5);
    }
  }

  h2.uptime-title, h2.group-title {
    font-size: 27px;
    font-weight: 600;
    letter-spacing: -0.021em;
    margin-bottom: 40px;

    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      opacity: .6;
      letter-spacing: -0.017em;
    }
  }
</style>
