<template>
  <div class="Maintenances">
    <div class="title">{{ textMaintenanceHistory }}</div>
    <template v-if="isShowHistory">
      <div class="date" v-for="(historyItem, index) in history" :key="index">
        <MaintenanceHistoryItem :history-item="historyItem" :status-page-language="statusPageLanguage"/>
      </div>
    </template>
    <div v-else class="no-maintenances">
      {{ textNoMaintenanceHistory }}
    </div>
  </div>
</template>

<script>
import statusPagesApi from '@/api/statusPagesApi'
import getUserLocale from 'get-user-locale'
import MaintenanceHistoryItem from '@/components/StatusPage/Maintenance/MaintenanceHistoryItem.vue'

export default {
  components: {
    MaintenanceHistoryItem
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      history: [],
      statusPageLanguage: {}
    }
  },

  created () {
    this.getMaintenanceHistory()
    if (this.statusPage && this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }
  },

  methods: {
    async getMaintenanceHistory () {
      try {
        const response = await statusPagesApi.getMaintenanceHistoryBySlug(this.statusPage.slug)
        this.history = response.data
      } catch (error) {
        console.error(error)
      }
    }
  },

  computed: {
    textMaintenanceHistory () {
      if (this.statusPageLanguage.text_maintenance_history) {
        return this.statusPageLanguage.text_maintenance_history
      }
      return 'Maintenance History'
    },

    isShowHistory () {
      return this.history.length > 0
    },

    textNoMaintenanceHistory () {
      if (this.statusPageLanguage.text_no_maintenance) {
        return this.statusPageLanguage.text_no_maintenance
      }
      return 'No maintenance.'
    }
  },

  filters: {
    date (timestamp) {
      const locale = getUserLocale()

      const day = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })

      return day.format(new Date(timestamp))
    },

    shortDate (timestamp) {
      const shortDate = new Intl.DateTimeFormat('en-GB', {
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric'
      })

      return shortDate.format(new Date(timestamp))
    }
  }
}
</script>

<style lang="scss" scoped>
.Maintenances {
  .title {
    font-size: 27px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: 600;
  }

  .date {
    margin-bottom: 40px;
  }

  .date-title {
    font-size: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    //border-bottom: 1px solid map-get($colors, gray-3);
  }

  .maintenance-item {
    padding: 30px;
    background: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .maintenance-item-update {
      //display: block;

      //@media (max-width: 500px) {
      //  display: block;
      //}

      .message {
        //margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;

        @media (max-width: 500px) {
          font-size: 16px;
        }
      }

      .title-line {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        @media (max-width: 360px) {
          display: block;
        }

        .title-name {
          font-weight: 600;
          margin-right: 10px;
          max-width: 405px;
        }

        .posted-at {
          font-weight: 500;
          font-size: 14px;
          color: rgba(25, 34, 29, 0.5);
          white-space: nowrap;
        }
      }
    }
  }
  .no-maintenances {
    height: 120px;
    background: rgba(25, 34, 29, 0.03);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(25, 34, 29, .5);
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: -10px 0;
  }
}
</style>
