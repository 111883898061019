<template>
  <div class="UptimeHistory"
       :class="$mq">
    <div class="statuses" ref="snapshots">
      <history-item
        v-for="item in items"
        :key="item.date"
        :item="item"
        :x="item.x"
        :width="item.width"
        :status-page-language="statusPageLanguage"
        :uptime-threshold="uptimeThreshold"
        :show-location-tooltip="showLocationTooltip"
        :user-time-zone="userTimeZone"
        :monitor-created-at='monitor.created_at'
      />
    </div>
<!--     <div class="labels">
      <div class="label">{{ numberOfDays }} days ago</div>
      <div class="spacer d-none d-sm-block"></div>
      <div class="label">{{ uptime | uptime }} uptime</div>
      <div class="spacer d-none d-sm-block"></div>
      <div class="label">today</div>
    </div> -->
  </div>
</template>

<script>
import dayjs from 'dayjs'
import HistoryItem from './UptimeHistoryItem.vue'
import { scaleBand } from 'd3-scale'
import monitorsApi from '@/api/monitorsApi'

export default {
  components: {
    HistoryItem
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    },
    uptimeThreshold: {
      required: false,
      type: Number,
      default: 1.00
    },

    showLocationTooltip: {
      required: false,
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      items: [],
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? null
    }
  },

  mounted () {
    // Should be in mounted since we getting scale depending on client width
    this.initIntervals()
  },

  methods: {

    async initIntervals () {
      const downtimes = await monitorsApi.getMonitorEachDayUptime(this.monitor.id, { 'days': this.numberOfDays })

      const dates = this.getDatesArray()

      let xScale = this.getXScale(dates)

      let items = dates.map(date => ({
        date,
        snapshots: [],
        x: xScale(date),
        width: xScale.bandwidth(),
        uptime: 1
      }))

      this.monitor.snapshots.forEach(snapshot => {
        let item = items.find(item => item.date === snapshot.date)

        if (item) {
          item.snapshots.push(snapshot)
        }
      })

      Object.keys(downtimes).forEach(date => {
        let item = items.find(item => item.date === date)

        if (item) {
          item.uptime = (86400 - downtimes[date]) / 86400
        }
      })

      let lastTwoItems = [items.length - 1, items.length - 2]
      for (let i of lastTwoItems) {
        if (items[i].snapshots.length > 0) {
          break
        } else {
          items.splice(i, 1)
        }
      }

      if (this.monitor.created_at) {
        const monitorCreated = dayjs(this.monitor.created_at).format('YYYY-MM-DD')
        const difference = dayjs().diff(monitorCreated, 'day')

        if (difference < this.numberOfDays) {
          items.forEach(item => {
            if (item.snapshots.length === 0) {
              item.snapshots = [1]
            }
          })
        }
      }

      this.items = items
    },

    getXScale (domain) {
      const width = this.$refs.snapshots.clientWidth

      return scaleBand()
        .range([0, width])
        .domain(domain)
        .paddingInner(0.3)
    },

    getDatesArray () {
      let dates = []

      for (let date = this.since; !date.isAfter(this.till, 'day'); date = date.add(1, 'day')) {
        dates.push(date.format('DD-MM-YYYY'))
      }

      return dates
    }
  },

  computed: {
    numberOfDays () {
      if (this.$mq === 'desktop') return 90
      if (this.$mq === 'tablet') return 60
      return 30
    },

    since () {
      return dayjs().subtract(this.numberOfDays, 'day').startOf('day')
    },

    till () {
      return dayjs().endOf('day')
    },

    uptime () {
      let total = 0
      let number = 0

      this.items.forEach(item => {
        item.snapshots.forEach(snapshot => {
          total += snapshot.uptime
          number++
        })
      })

      return total / number
    }
  },
  watch: {
    '$mq': function () {
      this.initIntervals()
    },
    monitor: {
      handler: function () {
        this.initIntervals()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .statuses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  .UptimeHistory {
    margin-bottom: 40px;

    // .labels {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }

    // .label {
    //   color: #888799;
    //   font-size: 14px;
    // }

    // .spacer {
    //   flex: 1 0;
    //   height: 1px;
    //   margin: 0 20px;
    //   border-top: 1px solid #DAE4F7;
    // }

    .snapshots {
      width: 100%;
      height: 100%;

      margin-bottom: 10px;
    }

    &.mobile {
      .label {
        font-size: 12px;
      }
    }
  }
</style>
