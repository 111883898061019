<template>
  <div class="MaintenanceItem">
    <div class="maintenance-badge">{{ maintenanceBadgeText }}</div>

      <div class="title-line">
        <div class="start-at">
           {{scheduledOnText + ' ' + maintenance.start }}
        </div>
        <div class="title mb-2">{{ maintenance.name }}</div>
        <nl2br tag="div" :text="maintenance.description" />
      </div>

  </div>
</template>

<script>
import Nl2br from 'vue-nl2br'
export default {
  components: {
    Nl2br
  },
  props: {
    maintenance: {
      require: true,
      type: Object
    },
    statusPageLanguage: {
      require: false
    }
  },

  computed: {
    maintenanceBadgeText () {
      if (this.statusPageLanguage.text_maintenance) {
        return this.statusPageLanguage.text_maintenance
      }
      return 'Maintenance'
    },

    scheduledOnText () {
      if (this.statusPageLanguage.text_scheduled_on) {
        return this.statusPageLanguage.text_scheduled_on
      }
      return 'Scheduled on'
    }
  }
}
</script>

<style lang="scss" scoped>
.MaintenanceItem {
  padding: 30px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;

  @media (max-width: 500px) {
    display: block;
  }

  .maintenance-badge {
    @include inline-flex-center;
    padding: 1px 16.5px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    border-radius: 18px;
    margin-right: 30px;

    flex-shrink: 0;
    background-color: #4e67eb;
  }

  .title-line {
    display: block;
    margin-bottom: 10px;

    .title {
      font-weight: 600;
      margin-right: 10px;
      max-width: 405px;
      display: block;
      font-size: 18px;
    }

    .start-at {
      font-weight: 500;
      font-size: 14px;
      color: rgba(25, 34, 29, 0.5);
      white-space: nowrap;
    }
  }
}
</style>
